'use client';
import { ProductUtils } from '@services/mage/catalog-product-utils';
import { TSaleOption } from 'src/services/mage/catalog-product-types';
import React from 'react';

type TProps = {
  lang: string;
  data: TSaleOption;
  price: number;
  className?: string;
};

export const ProductPriceDisplay = ({
  lang,
  data,
  price,
  className,
}: TProps) => {
  return (
    <div className="flex items-center product-price-wrapper">
      {data && Number(data?.discount_amt) > 0 ? (
        <>
          <span className="discounted-price text-red-700">
            {ProductUtils.formatPrice(data?.discount_amt, lang)}
          </span>
          <span
            className={`final-price line-through ml-2 ${className ? className : 'text-brand-grey-1'}`}
          >
            {ProductUtils.formatPrice(price, lang)}
          </span>
          <span className="text-white bg-red-700 px-3 ml-2 font-normal">
            -{data.discount_lbl}
          </span>
        </>
      ) : (
        <span className="final-price">
          {ProductUtils.formatPrice(price, lang)}
        </span>
      )}
    </div>
  );
};
