/**
 * ProductUtils
 */

import {
    AllCategories,
  csProduct,
  DIAMOND_CATEGORIES,
  ENGAGEMENT_RINGS,
  ENGRAVING_PRICE,
  GEMSTONE_CATEGORIES,
  JewelleryMainCategories,
  JewelleryStyles,
  mainCategories,
  MetalColor,
  metalColorNames,
  PRODUCT_MAGE_URL,
  SettingStylesRef,
  SimplifiedCategories,
  WeddingBandMainCategories,
  WeddingBandStyles,
} from './catalog-product-config';
import { filter, invert, isEmpty } from 'lodash';
import {
  ProductOptionsType,
  ProductOptionValuesType,
  ProductType,
  ProductMediaGalleryEntries,
  TProduct,
  ProductCategoryLinks,
  TSteps,
  TMetal,
  ProductOptionsFormType,
  TEngrave,
  TRemarks,
  TMediaGallery,
  TCsItem,
  TProductMeta,
  TSaleOption,
} from './catalog-product-types';
import { DefaultImage } from '@components/product/default-image';
import { TDiamond } from '@services/stone/types';
import { StoneHelper } from '@services/stone/helper';
import { IItem } from '@contexts/store/store-types';
import { getFormattedPrice } from '@framework/product/use-price';
import http from '@framework/utils/http';
import { generateItemId, isEmptyObject } from '@utils/tools';
import { ProductPriceDisplay } from '@components/product/product-details/product-price-display';
import { GetStoreCurrency } from './cms-helper';
import { GetCurrencyConfig } from './directory-helper';


export class ProductUtils {
  static getGold999UrlId = (GoldStyles: object, typeName: string) => {
    try {
      const urlId = Object.entries(GoldStyles['999']).find(
        ([key, value]) => key === typeName,
      )[1];
      return urlId ?? '';
    } catch (error) {
      return 149;
    }
  };

  static getMetalColorQuery = (searchParams: URLSearchParams) => {    
    
    const metalColor = searchParams.get('metal') ?? '';
    return metalColorNames[metalColor] ?? [];

  };

  static getStyleUrlPath = (pathname: string, stylesRef: object) => {
        
    // @ts-ignore
    const [store, path1, path2, path3, path4] = pathname.split('/');

    // 2 paths will be the style path
    if (path3 !== undefined && path4 !== undefined) {
      const style1 = stylesRef[path3] ?? '';
      const styles = style1[path4] ?? '';
      return styles ?? '';
    }

    // only single path
    if (path3 !== undefined) {
      const styles = stylesRef[path3] ?? '';
      return styles ?? '';
    }

    return '';
  };

  static getMetalColor(sku: string): string {
    const fchar = sku.split('-');
    const ret = fchar ? fchar[fchar.length - 1] : sku;
    return ret.toLocaleUpperCase();
  }

  static skuLastPart = (sku: string) => {
    const skuParts = sku.split('-');

    if (skuParts.length === 0) return sku;

    // get the last part
    return skuParts[skuParts.length - 1];
  };

  static getOptionValues = (
    options: ProductOptionsType[],
    title: string,
  ): ProductOptionValuesType[] => {
    const result = filter(options, (o) => {
      return o?.title === title;
    }).map((o: ProductOptionsType) => {
      return o?.values;
    })[0];

    return result ?? [];
  };

  static getGalleryImages = <TData>(product: ProductType): TData => {
    const images = filter(product.media_gallery_entries) as TData;

    return images;
  };

  static getMainCategory = (product: TProduct): string => {
    const extAttr = product.extension_attributes;
    let mainCategory = '';

    if (
      extAttr &&
      extAttr.category_links !== undefined &&
      extAttr.category_links.length
    ) {
      const category = extAttr.category_links[0];

      mainCategory =
        mainCategories[
          parseInt(category.category_id) as keyof typeof mainCategories
        ];
    }

    return mainCategory;
  };

  static getUrlPath = (categoryId: number, mainId?: number): string => {
    let path = '';

    let mainCategories = SimplifiedCategories;

    if (mainId) {
      mainCategories = SimplifiedCategories[mainId];

      Object.entries(mainCategories).forEach(([key, value]) => {
        if (Number(key) === Number(categoryId)) {
          path = value.toString();
          return false;
        }
      });

      return path;
    }

    // no parent category
    Object.entries(mainCategories).forEach(([key, value]) => {
      if (parseInt(key) !== categoryId) {
        Object.entries(value).forEach(([key2, value2]) => {
          if (typeof value2 === 'object') {
            Object.entries(value2).forEach(([key3, value3]) => {
              if (categoryId === parseInt(key3)) {
                path = value3 as string;
                return false;
              }
            });
          } else {
            if (categoryId === parseInt(key2)) {
              path = value2 as string;
              return false;
            }
          }
        });
      }
    });

    return path;
  };

  // get product meta info
    static getProductMeta = (product: TProduct) => {
        const customAttr = product.custom_attributes ?? [];

        const metaTitle = customAttr.find(
        (item) => item.attribute_code === 'meta_title',
        )?.value;

        const metaDescription = customAttr.find(
        (item) => item.attribute_code === 'meta_description',
        )?.value;

        const metaKeyword = customAttr.find(
        (item) => item.attribute_code === 'meta_keyword',
        )?.value;
    
        return {
            metaTitle: metaTitle ?? product.name,
            metaDescription: metaDescription ?? '',
            metaKeywords: metaKeyword ?? '',
        } as TProductMeta;
    }

  /**
   * Note: temporary set the product sku as the id
   * @param product
   * @returns
   */
  static getProductUrl = (
    product: TProduct,
    mainCategory?: string,
    subCategory?: string,
    lang: string = 'sg',
  ) => {
    const extAttr = product.extension_attributes;
    const customAttr = product.custom_attributes ?? [];
    const path = [] as string[];

    const categoryIds = this.getCategoryIds(product);

    // Exception for engagement rings
    if (categoryIds.includes(4)) {
      const styleRef = customAttr.find(
        (item) => item.attribute_code === 'style',
      )?.value;

      if (styleRef && styleRef !== undefined) {
        const styles = styleRef.toString().split(',');

        if (styles.length > 0) {
          // set the second parameter as the main category
          const link = this.getUrlPath(parseInt(styles[0]), 4);

          if (link) {
            path.push(link);
          }
        }
      }
    } else {
      if (
        extAttr &&
        extAttr.category_links !== undefined &&
        extAttr.category_links.length
      ) {
        let found = false;

        extAttr.category_links.forEach((item) => {
          if (found) return false;

          const link = this.getUrlPath(parseInt(item.category_id));

          if (link) {
            path.push(link);
            found = true;
            return false;
          }
        });
      }
    }

    path.push(product.sku);
    // remove duplicates
    const cleanPath = path.filter(
      (item, index) => path.indexOf(item) === index,
    );

    return `/${lang}/` + cleanPath.join('/').toLocaleLowerCase();
  };

  static getProductDescription = (product: TProduct) => {
    const customAttr = product.custom_attributes ?? [];
    const description = customAttr.find(
      (item) => item.attribute_code === 'short_description',
    );

    return description?.value ?? '';
  };

  static getThumbnailImageUrl = (product: ProductType): string => {
    let thumbnail =
      Object.hasOwn(product, 'media_gallery_entries') &&
      product.media_gallery_entries.length > 0
        ? PRODUCT_MAGE_URL + product.media_gallery_entries[0].file
        : '';

        if (Object.hasOwn(product, 'images')) {
            thumbnail = PRODUCT_MAGE_URL + product?.images[0].file
        }

    return thumbnail ?? DefaultImage;
  };

  static getCustomAttrThumbnail = (product: ProductType): string => {
    const customAttr = Object.hasOwn(product, 'custom_attributes');

    if (customAttr) {
      const thumbnail = product?.custom_attributes?.find(
        (item) => item.attribute_code === 'thumbnail',
      );

      if (thumbnail) {
        return (PRODUCT_MAGE_URL + thumbnail.value) as string;
      }
    }

    return DefaultImage;
  };

  /**
   * Get the metal related thumbnail
   * @param metal
   * @returns
   */
  static getMetalRelatedThumbnail = (metal: TMetal) => {
    const thumbnail =
      metal.images?.thumbnail.replaceAll(PRODUCT_MAGE_URL, '') ?? DefaultImage;
    const hover =
      metal.images?.hover.replaceAll(PRODUCT_MAGE_URL, '') ?? DefaultImage;

    return {
      thumbnail: PRODUCT_MAGE_URL + thumbnail,
      hover: PRODUCT_MAGE_URL + hover,
    };
  };

  static getMetalRelatedProducts = (metalOptions: TMetal[], sku: string) => {
    return metalOptions?.find((item: any) => item.sku === sku);
  };

  static getHoverImageUrl = (
    product: ProductType,
    withPath: boolean = true,
  ): string => {
    if (
      Object.hasOwn(product, 'media_gallery_entries') &&
      product.media_gallery_entries.length > 0
    ) {
      const thumbnail =
        PRODUCT_MAGE_URL + product.media_gallery_entries[0].file;
      const image2 =
        product.media_gallery_entries[1] !== undefined
          ? product.media_gallery_entries[1].file
          : '';

      const hoverImage = image2 ? PRODUCT_MAGE_URL + image2 : thumbnail;

      return hoverImage ?? '';
    }

    if (Object.hasOwn(product, 'images')) {
      const thumbnail = PRODUCT_MAGE_URL + product.images[0].file;
      const image2 =
        product.images[1] !== undefined ? product.images[1].file : '';

      const hoverImage = image2 ? PRODUCT_MAGE_URL + image2 : thumbnail;

      return hoverImage ?? '';
    }

    return '';
  };

  static getStockInfo = (product: ProductType) => {
    return product.extension_attributes?.stock_item;
  };

  static getMetalColorId = (metal: string): string => {
    const metalColor = invert(MetalColor);
    return metalColor[metal] || '';
  };

  static isMetalColor = (metal: string): boolean => {
    const metalColor = invert(MetalColor);
    const lastPart = this.skuLastPart(metal);
    return metalColor[lastPart] !== undefined;
  };

  static getCategoryIds = (product: TProduct) => {
    if (isEmpty(product)) return [];

    const extAttr = Object.hasOwn(product, 'extension_attributes')
      ? product.extension_attributes
      : ({} as TProduct['extension_attributes']);
    const categoryIds = [] as number[];

    if (
      extAttr &&
      extAttr.category_links !== undefined &&
      extAttr.category_links.length
    ) {
      extAttr.category_links.forEach((item) => {
        categoryIds.push(parseInt(item.category_id));
      });
    }

    // if engagement ring
    if (categoryIds.includes(ENGAGEMENT_RINGS)) {

        const customAttr = product.custom_attributes ?? [];

        const style = customAttr.find(
        (item) => item.attribute_code === 'style',
        )?.value;        

        if (style) {
            const styles = style.toString().split(',');            
            styles.forEach((style) => {
                categoryIds.push(parseInt(style));
            });
        }
        
    }

    return categoryIds;
  };

  static serializeStoneDetails = (product: TDiamond): object => {
    const stoneDetail = new Map<string, string>();
    const result: Record<string, string> = {};

    stoneDetail.set('Stock Number', product.sku);
    stoneDetail.set('Stone Prefix', product.stone_prefix);
    stoneDetail.set(`${product.lab} Report No`, product.report_number);
    stoneDetail.set('Carat', product.carat);
    stoneDetail.set('Cut', product.cut ?? '');
    stoneDetail.set('Color', product.color);
    stoneDetail.set('Clarity', product.clarity);
    stoneDetail.set('Shape', product.shape);
    stoneDetail.set('Flourescence', product.fluorescence);
    stoneDetail.set('Stone Id', product.stone_number.trim());

    stoneDetail.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  };

  static generateStoneLinks = (product: TDiamond): ProductCategoryLinks[] => {
    const categoryLinks = [] as ProductCategoryLinks[];

    categoryLinks.push({ position: 0, category_id: '3' });

    // default category
    DIAMOND_CATEGORIES.forEach((category, idx) => {
      categoryLinks.push({
        position: idx,
        category_id: category.toString(),
      });
    });

    // Gemstone
    if (product.stone_prefix === 'GEM') {
      GEMSTONE_CATEGORIES.forEach((category, idx) => {
        categoryLinks.push({
          position: idx,
          category_id: category.toString(),
        });
      });
    }

    // Labgrown Stock
    if (product.stone_prefix === 'JMDL') {
      categoryLinks.push({ position: 1, category_id: '144' });
    }

    // Diamond Stock
    if (product.stone_prefix === 'JMD') {
      categoryLinks.push({ position: 1, category_id: '143' });
    }

    // Gemstone supplier stock
    if (product.stone_prefix === '1G') {
      categoryLinks.push({ position: 1, category_id: '146' });
    }

    if (product.stone_prefix === '1G') {
      categoryLinks.push({ position: 1, category_id: '146' });
    }

    // diamond on call
    if (['1D', '2D', '3D', 'AD'].indexOf(product.stone_prefix!)) {
      categoryLinks.push({ position: 1, category_id: '147' });
    }

    if (['1L', '1LS', 'AL'].indexOf(product.stone_prefix!)) {
      categoryLinks.push({ position: 1, category_id: '148' });
    }

    if (product.stone_type === 'labgrown_coloured') {
      categoryLinks.push({ position: 1, category_id: '133' });
    }

    return categoryLinks;
  };

  /* static generateItemOptions = (product: TProduct): ProductOptionsFormType => {

        return {};

    } */

  /**
   * Create stone product
   * @param product
   * @returns
   */
  static createStoneProduct = (product: TDiamond): TProduct => {
    const productName = StoneHelper.diamondName(product);
    const categoryLinks = ProductUtils.generateStoneLinks(product);

    return {
      id: generateItemId(product.sku),
      sku: product.stone_prefix + product.sku,
      name: productName,
      attribute_set_id: 4,
      price: product.cost,
      status: 1,
      visibility: 4,
      type_id: 'simple',
      created_at: '',
      updated_at: '',
      weight: 0,
      extension_attributes: {
        website_ids: [1, 2, 3],
        category_links: categoryLinks,
        stock_item: {
          item_id: 0,
          product_id: 0,
          stock_id: 0,
          qty: 1,
          is_in_stock: false,
          is_qty_decimal: false,
          show_default_notification_message: false,
          use_config_min_qty: false,
          min_qty: 0,
          use_config_min_sale_qty: 0,
          min_sale_qty: 0,
          use_config_max_sale_qty: false,
          max_sale_qty: 0,
          use_config_backorders: false,
          backorders: 0,
          use_config_notify_stock_qty: false,
          notify_stock_qty: 0,
          use_config_qty_increments: false,
          qty_increments: 0,
          use_config_enable_qty_inc: false,
          enable_qty_increments: false,
          use_config_manage_stock: false,
          manage_stock: false,
          low_stock_date: '',
          is_decimal_divided: false,
          stock_status_changed_auto: 0,
        },
      },
      product_links: [],
      options: [],
      media_gallery_entries: [
        {
          id: 1,
          media_type: 'image',
          label: product.sku,
          position: 1,
          disabled: false,
          types: ['image', 'small_image', 'thumbnail'],
          file: product.image,
        },
      ],
      tier_prices: [],
      custom_attributes: [],
    };
  };

  /**
   * Generate item
   */
  static createStoneItem = (product: TDiamond): IItem => {
    const price =
      Number(product?.sale_option?.discount_amt) > 0
        ? product?.sale_option?.discount_amt
        : product.cost;

    const item: IItem = {
      id: parseInt(product.id),
      productId: parseInt(product.id),
      sku: product.sku,
      name: StoneHelper.diamondName(product),
      price: price,
      quantity: 1,
      stock: 1,
      images: [],
      options: {
        metal: { option_type_id: '1', value: '' },
        engrave: {
          option_type_id: 2,
          font: '',
          price: 0,
          text: '',
          title: 'No',
          sort_order: 0,
          price_type: '',
          sku: '',
        },
        ringSize: {
          option_type_id: 3,
          price: 0,
          title: '',
          sort_order: 0,
          price_type: '',
          sku: '',
        },
        remarks: { option_type_id: '4', value: '' },
      },
      options_ref: [],
      productType: 'stone',
      saleOption: product.sale_option,
    };

    return item;
  };

  static populateRingSize = (product: TProduct) => {
    const ringSize = product.options.filter(
      (item) => item.title === 'Ring Size',
    )[0].values;
    const ringSizeValues = [] as ProductOptionValuesType[];

    Object.entries(ringSize).forEach(([key, value]) => {
      ringSizeValues.push({
        option_type_id: value.option_type_id,
        price: value.price,
        title: value.title,
        sort_order: value.sort_order,
        price_type: value.price_type,
        sku: product.sku,
      });
    });

    return ringSizeValues;
  };

  static createProductItem = (product: TProduct): IItem => {
    const galleryImages =
      ProductUtils.getGalleryImages<ProductMediaGalleryEntries[]>(product);
    const stockInfo = ProductUtils.getStockInfo(product);
    const categoryIds = ProductUtils.getCategoryIds(product);
    const productUrl = ProductUtils.getProductUrl(product);

    const price =
      product.sale_option?.discount_amt > 0
        ? product.sale_option?.discount_amt
        : product.price;

    const item: IItem = {
      id: generateItemId(product.sku),
      productId: product.id as number,
      sku: product.sku,
      name: product.name,
      price: price,
      originalPrice: product.price,
      quantity: 1,
      stock: stockInfo?.qty,
      images: galleryImages,
      options: {
        metal: { option_type_id: '1', value: '' },
        engrave: {
          option_type_id: 2,
          font: '',
          price: 0,
          text: '',
          title: 'No',
          sort_order: 0,
          price_type: '',
          sku: '',
        },
        ringSize: {
          option_type_id: 3,
          price: 0,
          title: '',
          sort_order: 0,
          price_type: '',
          sku: '',
        },
        remarks: { option_type_id: '4', value: '' },
      },
      options_ref: product.options,
      productType: 'product',
      categoryIds: categoryIds,
      productUrl: productUrl,
      saleOption: product.sale_option,
    };

    return item;
  };

  static changeUrlLast = (urlPath: string): string => {
    const urlParts = urlPath.split('/');
    const urlParts2 = urlParts.slice(0, urlParts.length - 1);
    const url = urlParts2.join('/');

    return url;
  };

  static updateProductSteps = (
    lang: string,
    steps: TSteps[],
    setting: TProduct,
    diamond: TDiamond,
    getTotal: () => number,
    resetAll: () => void,
  ) => {
    if (!isEmptyObject(setting)) {
      const settingProductUrl = ProductUtils.getProductUrl(
        setting,
        '',
        '',
        lang,
      );
      const settingChangeUrl = ProductUtils.changeUrlLast(settingProductUrl);

      const price =
        setting.sale_option?.discount_amt > 0
          ? setting.sale_option?.discount_amt
          : setting.price;
      const priceDisplay = ProductPriceDisplay({
        lang: lang,
        data: setting.sale_option,
        price: setting.price,
      });

      steps.filter((step) => step.id === '2')[0].data = {
        name: setting.name,
        price: price,
      };

      steps.filter((step) => step.id === '2')[0].name = 'Setting';
      steps.filter((step) => step.id === '2')[0].status = 'complete';
      steps.filter((step) => step.id === '2')[0].href = settingProductUrl;
      steps.filter((step) => step.id === '2')[0].changeHref = settingChangeUrl;

      steps.filter((step) => step.id === '2')[0].data = {
        name: setting.name,
        price: priceDisplay,
      };

      steps.filter((step) => step.id === '2')[0].name = 'Setting';
      steps.filter((step) => step.id === '2')[0].status = 'complete';
      steps.filter((step) => step.id === '2')[0].image =
        ProductUtils.getCustomAttrThumbnail(setting);
      steps.filter((step) => step.id === '2')[0].isImage = true;

      steps.filter((step) => step.id === '3')[0].image =
        ProductUtils.getCustomAttrThumbnail(setting);
      steps.filter((step) => step.id === '3')[0].isImage = true;
    }

    if (!isEmptyObject(diamond)) {
      const stoneProductUrl = StoneHelper.productUrl(lang, diamond, lang);
      const stoneChangeUrl = ProductUtils.changeUrlLast(stoneProductUrl);

      const price =
        diamond.sale_option?.discount_amt > 0
          ? diamond.sale_option?.discount_amt
          : diamond.cost;
      const priceDisplay = ProductPriceDisplay({
        lang: lang,
        data: diamond.sale_option,
        price: diamond.cost,
      });

      steps.filter((step) => step.id === '1')[0].data = {
        name: StoneHelper.productName(diamond),
        price: price,
      };

      steps.filter((step) => step.id === '1')[0].name =
        diamond.lab === 'GEM' ? 'Gemstone' : 'Diamond';
      steps.filter((step) => step.id === '1')[0].status = 'complete';
      steps.filter((step) => step.id === '1')[0].href = stoneProductUrl;
      steps.filter((step) => step.id === '1')[0].changeHref = stoneChangeUrl;

      steps.filter((step) => step.id === '1')[0].data = {
        name: StoneHelper.productName(diamond),
        price: priceDisplay,
      };

      steps.filter((step) => step.id === '1')[0].name =
        diamond.lab === 'GEM' ? 'Gemstone' : 'Diamond';
      steps.filter((step) => step.id === '1')[0].status = 'complete';

      steps.filter((step) => step.id === '1')[0].image = diamond.image;
      steps.filter((step) => step.id === '1')[0].isImage = true;
    }

    /**
     * Complete tab
     */
    if (!isEmptyObject(setting) && !isEmptyObject(diamond)) {
      steps.filter((step) => step.id === '3')[0].data = {
        name: diamond.stone_prefix + diamond.sku + ' + ' + setting.sku,
        price: getTotal(),
      };

      steps.filter((step) => step.id === '3')[0].onClick = () => {
        resetAll();
      };
    }

    return steps;
  };

  static getCheckoutItemOptions(
    currentOptions: ProductOptionsFormType['options'],
    product: TProduct,
  ) {
    const optionValues: Record<any, string> = {};
    const options = product.options;

    // extract options values
    Object.entries(currentOptions).forEach((option) => {
      const optionTypeId = option[1].option_type_id;

      // get option id
      const originalOptionValues = options.find((o) =>
        o?.values?.find((v) => v.option_type_id == optionTypeId),
      );

      if (!isEmpty(originalOptionValues)) {
        if (option[0] === 'engrave') {
          const engraveOption = option[1] as TEngrave;

          // Get engrave yes/no
          if (engraveOption.title === 'Yes') {
            // option id
            optionValues[originalOptionValues?.option_id] =
              engraveOption.option_type_id.toString();

            // Get engrave text option id
            const getEngraveTextOptionId = options.find(
              (o) => o.title == 'Engraving Text',
            )?.option_id;

            if (getEngraveTextOptionId !== undefined)
              optionValues[getEngraveTextOptionId] = engraveOption.text;

            // Get engrave font
            const getEngraveFontOptionId = options.find(
              (o) => o.title == 'Engraving Font',
            )?.option_id;

            if (getEngraveFontOptionId !== undefined)
              optionValues[getEngraveFontOptionId] = engraveOption.font;
          }
        }

        if (option[0] === 'remarks') {
          const remarksOption = option[1] as TRemarks;
          optionValues[originalOptionValues?.option_id] = remarksOption.value;
        }

        if (option[0] !== 'engrave' && option[0] !== 'remarks')
          optionValues[originalOptionValues?.option_id] =
            option[1].option_type_id.toString();
      }
    });

    return optionValues;
  }

  /**
   * Get Product with Promo Price
   * @param sku
   * @returns
   */
  static getProductWithPromoPrice = async (sku: string, storeId: number) => {

    const vStoreId = storeId === 0 ? 1 : storeId;

    const priceInfo = await http
      .get(`/products/with-promo?sku=${sku?.toLowerCase()}&store_id=${vStoreId}`)
      .then((res) => res.data);

    return await priceInfo.result as TSaleOption
  };

  /**
   * Get all media gallery entries
   * @param product
   */
  static getAllMediaGalleryEntries = (product: ProductType) => {
    const mediaGalleryEntries = product?.media_gallery_entries ?? [];
    const customAttr = product?.custom_attributes ?? [];
    let mediaGallery = {} as TMediaGallery;

    // Get the model video
    const productVideo = customAttr.find(
      (attr) => attr.attribute_code === 'video_url',
    )?.value;
    const modelVideo = customAttr.find(
      (attr) => attr.attribute_code === 'model_video_url',
    )?.value;

    if (productVideo) {
      mediaGallery = {
        ...mediaGallery,
        productVideo: productVideo as string,
      };
    }

    if (modelVideo) {
      mediaGallery = {
        ...mediaGallery,
        modelVideo: modelVideo as string,
      };
    }

    // Get all images
    if (mediaGalleryEntries.length > 0) {
      const images = mediaGalleryEntries.map((entry) => {
        return PRODUCT_MAGE_URL + entry.file;
      });

      mediaGallery = {
        ...mediaGallery,
        images: images,
      };
    }

    return mediaGallery;
  };

  /**
   * Get engagement ring styles name from the id     *
   */
  static getEngagementRingStyleName = (id: number) => {
    const styles = Object.entries(SettingStylesRef).find(
      ([key, value]) => value === id,
    );

    return styles ? styles[0] : '';
  };

  /**
   * Get engagement ring style id
   * @param id
   * @param subpath
   * @returns
   */
  static getEngagementRingStyleId = (style: string) => {
    const styles = SettingStylesRef[style];

    return styles ?? '';
  };

  /**
   * Get wedding band styles name from the id
   */
  static getWeddingBandStyleName = (id: number, subpath: string) => {
    const styles = Object.entries(WeddingBandStyles[subpath]).find(
      ([key, value]) => value === id,
    );

    return styles ? styles[0] : '';
  };

  /**
   * Get wedding band style id
   */
  static getWeddingBandStyleId = (subpath: string, wType: string) => {
    const styles = WeddingBandStyles[subpath][wType];

    return styles ?? '';
  };

  /**
   * Get wedding band style id
   */
  static getWeddingBandMain = (subpath: string) => {
    const main = WeddingBandMainCategories[subpath];

    return main ?? '';
  };

  /**
   * Get Jewellery Styles name from the id
   *
   */
  static getJewelleryStyleName = (subpath: string, id: number) => {

    if (subpath === 'shop-all') {
        return '';
    }


    const styles = Object.entries(JewelleryStyles[subpath]).find(
      ([key, value]) => value === id,
    );

    return styles ? styles[0] : '';
  };

  /**
   * Get Jewellery Styles id
   */
  static getJewelleryStyleId = (subpath: string, type: string) => {
    const styles = JewelleryStyles[subpath][type];

    return styles ?? '';
  };

  /**
   * Get the category id from the url
   */
  static getJewelleryMain = (subpath: string) => {
    const main = JewelleryMainCategories[subpath];

    return main ?? '';
  };

  static formatPrice = (price: number, lang: string) => {
    const currencyCode = GetStoreCurrency(lang);
    const currency = GetCurrencyConfig(currencyCode);
    const exchangeRate = currency.exchangeRate;

    return getFormattedPrice({
      baseAmount: price,
      amount: price,
      currencyCode: currencyCode,
      currencyRate: exchangeRate,
      locale: 'en',
    });
  };

  static generateRingSizeValues = () => {

    const ringSizeValues = [] as ProductOptionValuesType[];

    for (let i = 3; i <= 24; i++) {
      ringSizeValues.push({
        option_type_id: i,
        price: 0,
        title: i.toString(),
        sort_order: 0,
        price_type: '',
        sku: '',
      });
    }

    return ringSizeValues;
  }

  // Create cs product
  static createCSProduct = (product: TCsItem, productId: number) => {
    const vProduct = csProduct;    

    const name = `${product.modelNumber} ${product.ringSize} ${product.engravingFont} ${product.engravingText}`;    

    vProduct.name = name;
    vProduct.sku = product.sku;
    vProduct.price = Number(product.itemPrice);
    vProduct.weight = 0;
    vProduct.status = 1;
    vProduct.attribute_set_id = 4;
    vProduct.visibility = 3;
    vProduct.extension_attributes.website_ids = [1, 2, 3];
    vProduct.extension_attributes.category_links = [
      { position: 0, category_id: '124' },
    ];
    vProduct.extension_attributes.stock_item.qty = 1;
    vProduct.extension_attributes.stock_item.is_in_stock = true;
    vProduct.id = productId;
    vProduct.media_gallery_entries = [
      {
        id: 1,
        media_type: 'image',
        label: '',
        position: 0,
        disabled: false,
        types: ['image'],
        file: '/placeholder/default/product-image-coming-soon_3.jpg',
      },
    ];
    vProduct.metal_options = [];
    vProduct.options = [
      {
        product_sku: product.sku,
        option_id: 1,
        title: 'Metal',
        type: 'radio',
        sort_order: 1,
        is_require: true,
        max_characters: 0,
        image_size_x: 0,
        image_size_y: 0,
        values: [
          {
            title: product.sku,
            sort_order: 0,
            price: 0,
            price_type: 'fixed',
            sku: '',
            option_type_id: 0,
          },
        ],
      },
      {
        product_sku: product.sku,
        option_id: 2,
        title: 'Engraving',
        type: 'radio',
        sort_order: 2,
        is_require: true,
        max_characters: 0,
        image_size_x: 0,
        image_size_y: 0,
        values: [
          {
            title: 'Yes',
            sort_order: 0,
            price: ENGRAVING_PRICE,
            price_type: 'fixed',
            option_type_id: 1,
          },
          {
            title: 'No',
            sort_order: 1,
            price: 0,
            price_type: 'fixed',
            option_type_id: 2,
          },
        ],
      },
      {
        product_sku: product.sku,
        option_id: 3,
        title: 'Engraving Text',
        type: 'field',
        sort_order: 3,
        is_require: false,
        price: 0,
        price_type: 'fixed',
        max_characters: 0,
        image_size_x: 0,
        image_size_y: 0,
        values: [
          {
            title: 'Engraving Text',
            sort_order: 0,
            price: 0,
            price_type: 'fixed',
            option_type_id: 1,
          },
        ],
      },
      {
        product_sku: product.sku,
        option_id: 3,
        title: 'Engraving Font',
        type: 'field',
        sort_order: 4,
        is_require: false,
        price: 0,
        price_type: 'fixed',
        max_characters: 0,
        image_size_x: 0,
        image_size_y: 0,
        values: [
          {
            title: 'Engraving Font',
            sort_order: 0,
            price: 0,
            price_type: 'fixed',
            option_type_id: 1,
          },
        ],
      },
      {
        product_sku: product.sku,
        option_id: 4,
        title: 'Ring Size',
        type: 'drop_down',
        sort_order: 5,
        is_require: true,
        max_characters: 0,
        image_size_x: 0,
        image_size_y: 0,
        values: [
          {
            title: product.ringSize,
            sort_order: 1,
            price: 0,
            price_type: 'fixed',
            option_type_id: 1,
          },
        ],
      },
      {
        product_sku: product.sku,
        option_id: 5,
        title: 'Remarks',
        type: 'area',
        sort_order: 6,
        is_require: false,
        price: 0,
        price_type: 'fixed',
        max_characters: 0,
        image_size_x: 0,
        image_size_y: 0,
        values: [
          {
            title: 'Remarks',
            sort_order: 0,
            price: 0,
            price_type: 'fixed',
            option_type_id: 1,
          },
        ],
      },
    ];


    // generate item options
    //vProduct.options_ref = ringSizeValues;

    return vProduct;
  };

    /**
     * Get the category names from the category ids
     */
    static getCategoryNames = (categoryIds: number[]) => {
        const categoryNames = [] as string[];
        
        categoryIds.forEach((id) => {
            const category = Object.entries(AllCategories).find(([key]) => Number(key) === id);
            if (category) {
                const names = category[1].split('>')
                names.forEach(name => {
                    categoryNames.push(name.trim());
                });                
            }
        });

        return categoryNames;
    }

    static isMainCategory = (main: string) => {
        // find by name
        const found = Object.entries(mainCategories).find(([key, value]) => value === main);
        return found ? true : false;
    }


  /*  static unshiftEmptyRingSize = (options: ProductOptionValuesType[]) => {
      const defaultValue = {option_type_id: 0, title: 'Select Ring Size', price: 0, price_type: 'fixed', sku: '', sort_order: 0};

      let newOptions = options

      // check if no duplicate values
      newOptions.values.unshift(defaultValue);

      return newOptions;
    } */
}
