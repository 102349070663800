/**
 * Stone service configuration
 */

import { max } from "lodash";


export const DOCApiConfig = {
    USER_ID: 2947,
    DOC_CONFIRM_URL : 'https://api.global.diamondsoncall.com/INTL/confirmDiamondFetch',
    DOC_HOLD_URL : 'https://api.global.diamondsoncall.com/INTL/holdDiamondFetch'
}


const stoneConfig = {
  LocalStock: ['JMD', 'JMDL', '1LS', '1LM'],
  lab: [
    { name: 'GIA', link: 'https://www.gia.edu' },
    { name: 'IGI', link: 'https://www.igi.org' },
    { name: 'GEM', link: 'https://www.gem.org' },
  ],
};

export const CERTIFICATE_LINKS = {
  igi: 'https://www.igi.org/verify-your-report/?r=%s',
  gia: 'https://www.gia.edu/report-check?reportno=%s',
};

export const STONE_SAMPLE_THUMBNAILS = {
  AS: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-asscher.jpg',
  CU: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-cushion.jpg',
  EM: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-emerald.jpg',
  HS: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-heart.jpg',
  MQ: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-marquise.jpg',
  OC: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-octagon.jpg',
  OV: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-oval.jpg',
  PE: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-pear.jpg',
  PR: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-princess.jpg',
  RA: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-radiant.jpg',
  RB: 'https://d213t4exkgxsjh.cloudfront.net/assets/images/diamond-sample-round.jpg',
};

const filterConfig = {
  Diamond: [
    {
      id: 'diamond',
      title: 'Earth Natural Diamonds',
      url: 'loose-diamonds/earth-natural-diamonds',
    },
    {
      id: 'labgrown',
      title: 'Lab Grown Diamonds',
      url: 'loose-diamonds/lab-grown-diamonds',
    },
  ],

  Gemstone: {
    gemstone: 'Gemstones',
  },
  DiamondShapes: {
    round: 'RB',
    princess: 'PR',
    emerald: 'EM',
    asscher: 'AS',
    marquise: 'MQ',
    cushion: 'CU',
    radiant: 'RA',
    pear: 'PE',
    oval: 'OV',
    heart: 'HS',
    octagon: 'OC',
  },

  StoneTypes: {
    diamond: 'earth-natural-diamonds',
    labgrown: 'lab-grown-diamonds',
    labgrown_coloured: 'fancy-colour-diamonds/lab-grown-diamonds',
    natural_coloured: 'fancy-colour-diamonds/earth-natural-diamonds',
  },

  GemstoneShapes: {
    round: 'RB',
    princess: 'PR',
    emerald: 'EM',
    asscher: 'AS',
    marquise: 'MQ',
    cushion: 'CU',
    radiant: 'RA',
    pear: 'PE',
    oval: 'OV',
    heart: 'HS',
    octagon: 'OC',
  },
  GemstoneTypes: {
    alexandrite: 'alexandrite',
    amethyst: 'amethyst',
    aquamarine: 'aquamarine',
    'color-change-sapphire': 'color-change-sapphire',
    demantoid: 'demantoid',
    emerald: 'emerald',
    morganite: 'morganite',
    rhodolite: 'rhodolite',
    ruby: 'ruby',
    sapphire: 'sapphire',
    'fancy-sapphire': 'fancy-sapphire',
    'star-sapphire': 'star-sapphire',
    spinel: 'spinel',
    tanzanite: 'tanzanite',
    topaz: 'topaz',
    tourmaline: 'tourmaline',
    tsavorite: 'tsavorite',
  },
  DefaultRange: { min: 0, max: 100 },
  CaratRange: { min: 0.23, max: 13.52, maxIndex: 150},
  PriceRange: { min: 258, max: 1878505 },
  StonePriceRangeConfig: {
    'sg': { min: 25, max: 1878505, priceIndex: 243},
    'my': { min: 88, max: 6668693, priceIndex: 243},
    'intl': { min: 25, max: 1878505, priceIndex: 243},
  },
  JewelleryPriceRangeConfig: {
    'sg': { min: 38, max: 44599, priceIndex: 176},
    'my': { min: 134, max: 158326, priceIndex: 176},
    'intl': { min: 38, max: 44599, priceIndex: 176},
  },
  JewelleryPriceRange: { min: 100, max: 3399 },
  DepthRange: { min: 0.00, max: 88.10 },
  TableRange: { min: 0, max: 88 },
  LwRange: { min: 0.00, max: 2.21 },
  CutMarks: {
    G: 'Good',
    VG: 'Very Good',
    EX: 'Excellent',
    PH: 'Pure Hearts',
  },
  CutMarksLab: {
    G: 'Good',
    VG: 'Very Good',
    EX: 'Excellent',
    ID: 'Ideal'
  },
  CutMarksLabGIA: {
    G: 'Good',
    VG: 'Very Good',
    EX: 'Excellent',
  },
  ColorMarks: {
    K: 'K',
    J: 'J',
    I: 'I',
    H: 'H',
    G: 'G',
    F: 'F',
    E: 'E',
    D: 'D',
  },

  /*     'GemstoneClarityMarks' : {
        'EC1': 'EC1',
        'EC2': 'EC2',
        'I1': 'I1',
        'I2': 'I2',
        'None': 'None',
        'Opaque': 'Oque',
        'Translucent': 'Trans',
        'VI1': 'VI1',
        'VI2': 'VI2',
    }, */

  GemstoneColorMarks: {
    blue: 'Blue',
    brown: 'Brown',
    gray: 'Gray',
    green: 'Green',
    orange: 'Orange',
    pink: 'Pink',
    purple: 'Purple',
    red: 'Red',
    violet: 'Violet',
    white: 'White',
    yellow: 'Yellow',
    mixed: 'Mixed',
  },

  ClarityMarks: {
    SI2: 'SI2',
    SI1: 'SI1',
    VS2: 'VS2',
    VS1: 'VS1',
    VVS2: 'VVS2',
    VVS1: 'VVS1',
    IF: 'IF',
    FL: 'FL',
  },
  PolishMarks: {
    F: 'Fair',
    G: 'Good',
    VG: 'Very Good',
    EX: 'Excellent',
  },
  SymmetryMarks: {
    F: 'Fair',
    G: 'Good',
    VG: 'Very Good',
    EX: 'Excellent',
  },
  FluorescenceMarks: {
    vst: 'Very Strong',
    stg: 'Strong',
    med: 'Medium',
    fnt: 'Faint',
    non: 'None',
  },
  Intensity: {
    fnt: 'Faint',
    vlg: 'Very Light',
    lgt: 'Light',
    fcy: 'Fancy',
    ite: 'Intense',
    vvd: 'Vivid',
    dep: 'Deep',
    drk: 'Dark',
  },

  sortOptionsStone: [
    { name: 'Price: Low to High', value: 'cost-asc' },
    { name: 'Price: Hight to Low', value: 'cost-desc' },
    { name: 'Carat: Low to High', value: 'carat-asc' },
    { name: 'Carat: Hight to Low', value: 'carat-desc' },
    { name: 'Cut: Low to High', value: 'cut-asc' },
    { name: 'Cut: High to Low', value: 'cut-desc' },
    { name: 'Color: Low to High', value: 'color-asc' },
    { name: 'Color: High to Low', value: 'color-desc' },
    { name: 'Clarity: Low to High', value: 'clarity-asc' },
    { name: 'Clarity: High to Low', value: 'clarity-desc' },
  ],

  /*   ColouredDiamond: {
    'yellow': '',
    'pink': '',
    'purple': '',
    'red': '',
    'blue': '',
    'green': '',
    'orange': '',
    'brown': '',
    'gray': '',
    'black': '',
    'salt-pepper': '',
  }, */

  convertValueToKey: (value: string, marks: Record<string, string>) => {
    return Object.keys(marks).find((key) => marks[key] === value);
  },
};

export { filterConfig, stoneConfig };
