import crypto, { createHmac } from 'crypto';

export class Jcrypto {
  // Defining algorithm
  private static algorithm = 'aes-256-cbc';

  /* const secret = 'abcdefg';
const hash = createHmac('sha256', secret)
               .update('I love cupcakes')
               .digest('hex'); */

  // Defining key
  private static key = 'testkey';

  // Defining iv
  private static iv = 'testiv';

  static encrypt = (text: string) => {
    // Creating Cipheriv with its parameter
    const cipher = crypto.createCipheriv('aes-256-cbc', this.key, this.iv);

    // Updating text
    let encrypted = cipher.update(text, 'utf8', 'hex');

    encrypted += cipher.final('hex');

    return encrypted;
  };

  static decrypt = (encrypted: string) => {
    const decipher = crypto.createDecipheriv('aes-256-cbc', this.key, this.iv);

    // decrypt the data
    let decryptedText = decipher.update(encrypted, 'hex', 'utf-8');

    decipher.setAutoPadding(false);

    // finalize the decryption
    decryptedText += decipher.final('utf-8');

    return decryptedText;

    /*  const text = encrypted.split(':');

        let iv = Buffer.from(text[0], 'hex');
        let encryptedText = Buffer.from(text[1], 'hex');
        
        // Creating Decipher
        let decipher = crypto.createDecipheriv(
                'aes-256-cbc', Buffer.from(this.key), iv);

        decipher.setAutoPadding(false);
        
        // Updating encrypted text
        let decrypted = decipher.update(encryptedText);        

        decrypted = Buffer.concat([decrypted, decipher.final()]);        
        
        // returns data after decryption
        return decrypted.toString(); */
  };
}

export const jdecrypt = (encrypted: string) => {
  const decipher = crypto.createDecipheriv(
    'aes-256-cbc',
    crypto.randomBytes(32),
    crypto.randomBytes(16),
  );

  // decrypt the data
  let decryptedText = decipher.update(encrypted, 'hex', 'utf-8');

  decipher.setAutoPadding(false);

  // finalize the decryption
  decryptedText += decipher.final('utf-8');

  return decryptedText;
};
